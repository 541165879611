<template>
  <div class="w-full vh-80 flex justify-center items-center">
    <YoutubeIframe
      :youtube-id="youtubeId"
    />
  </div>
</template>

<script>import { defineAsyncComponent } from 'vue'

const YoutubeIframe = defineAsyncComponent(() => import('@/components/common/YoutubeIframe.vue'))

export default {
  name: 'Player',
  components: {
    YoutubeIframe
  },
  computed: {
    youtubeId () {
      return this.$route.query.v
    }
  }
}
</script>

<style lang="scss" scoped>
.vh-80 {
  height: 80vh;
}
</style>
